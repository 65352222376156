import { WeekDays } from '../constants';

export const frTranslation = {
  addPointsModal: {
    title: 'Ajuster les points',
    actualScore: 'Solde de points actuel',
    addedPoints: 'Points ajoutés',
    removedPoints: 'Points enlevés',
    total: 'Total',
  },
  weekDays: {
    [WeekDays.SUNDAY]: 'Dimanche',
    [WeekDays.MONDAY]: 'Lundi',
    [WeekDays.TUESDAY]: 'Mardi',
    [WeekDays.WEDNESDAY]: 'Mercredi',
    [WeekDays.THURSDAY]: 'Jeudi',
    [WeekDays.FRIDAY]: 'Vendredi',
    [WeekDays.SATURDAY]: 'Samedi',
  },
  applicationTitle: 'Plaisirs & Privilèges',
  button: {
    add: 'Ajouter',
    cancel: 'Annuler',
    close: 'Fermer',
    confirm: 'Confirmer',
    confirmMigration: 'Confirmer la migration',
    continue: 'Continuer',
    create: 'Créer',
    delete: 'Supprimer',
    edit: 'Modifier',
    migrate: 'Migrer',
    remove: 'Supprimer',
    save: 'Enregistrer',
    activateAccount: 'Activer participant',
    deactivateAccount: 'Désactiver participant',
  },
  common: {
    dashboard: 'Dashboard',
    noPicture: 'Aucune image',
  },
  promotionBannerCreate: {
    title: 'Nouvelle bannière',
    listTitle: 'Bannières',
    breadCrumb: {
      title: 'Nouvelle bannière',
    },
    form: {
      nameInputLabel: 'Nom',
      position: 'Position',
      URLInputLabel: 'URL',
      upload: {
        title: 'Image',
        subtitle: "Ratio d'image 16x10 recommandé",
        required: 'Image requise',
      },
      instruction: {
        main: 'Sélectionnez une image',
        secondary: "Déposez l'image ou parcourez votre ordinateur",
      },
    },
  },
  promotionBannerList: {
    createButton: 'Ajouter une bannière',
    title: 'Bannières',
    listTitle: 'Bannières',
    name: 'Nom',
    url: 'URL',
    actions: 'Actions',
    breadCrumb: {
      title: 'Bannières',
    },
    dialogDelete: {
      title: 'Supprimer: {{name}}',
      description: 'Voulez-vous vraiment supprimer cette promotion ?',
      no: 'Non',
      yes: 'Oui',
    },
  },
  loyaltyList: {
    createButton: 'Ajouter',
    listTitle: 'Promotions & Privilèges',
    title: 'Promotions & Privilèges',
    privilegeName: 'Nom du privilège',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    type: 'Type',
    restaurant: 'Restaurant',
    actions: 'Actions',
    status: 'Statut',
    upcoming: 'Non débuté',
    inProgress: 'En cours',
    expired: 'Expiré',
    missingInfos: 'Informations manquantes',
    tabs: {
      all: 'Tous',
      privileges: 'Rabais',
      bonusPoints: 'Points Bonis',
      rewards: 'Récompenses',
    },
    reward: 'Récompense',
    dialogDelete: {
      title: 'Supprimer: {{name}}',
      description: 'Voulez-vous vraiment supprimer cette promotion ?',
      no: 'Non',
      yes: 'Oui',
    },
  },
  loyaltyDetails: {
    tabs: {
      privilege: 'Privilège',
      reward: 'Récompense',
      bonusPoints: 'Points Bonis',
      promoCode: 'Code promo',
    },
    form: {
      upload: {
        title: 'Image',
      },
      availability: 'Disponibilité',
      validFor: 'Valide pour',
      availabilityText:
        'Notez que la récompense sera toujours validée dans la nuit suivant le scan. La récompense sera donc disponible seulement le lendemain, jamais le jour même.',
      conditions: 'Conditions',
      lockPrivilege: 'Verrouiller la promotion',
      connexion: 'Connexion',
      usage: 'utilisation(s)',
      startTime: 'Heure de début',
      endTime: 'Heure de fin',
      specificDay: 'Jour/heure spécifique',
      specificRestaurants: 'Restaurant spécifique',
      specificProduct: 'Achat d’un produit spécifique',
      useOfRestoDollar: 'Utilisation de restos dollars',
      minimumValue: 'Valeur minimum',
      addition: 'Addition',
      multiplication: 'Multiplication',
      percent: 'Pourcentage',
      value: 'Valeur',
      validPeriod: 'Périodes valides',
      title: 'Nouvelle promotion',
      pointsValue: 'Valeur des points',
      editTitle: 'Modifier promotion',
      loyaltyType: 'Type de promotion',
      reward: 'Récompense',
      privilege: 'Rabais',
      bonusPoints: 'Points Bonis',
      informations: 'Informations',
      privilegeName: 'Nom du privilège',
      description: 'Description',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      termsOfUse: "Conditions d'utilisation",
      validRestaurants: 'Restaurants valides',
      allRestaurants: 'Tous les restaurants',
      mdCode: 'Code Maître D',
      mdPlu: 'Code PLU',
      usageNumber: "Nombre d'utilisations",
      persistent: 'Persistant',
      unique: 'Unique',
      stackable: 'Accumulable',
      employee: 'Employé',
      customer: 'Client',
      applyTo: 'Pour',
      maxUsageDisabled: 'Une promotion verrouillée ne peut être utilisée qu’une seule fois',
      atLeastOneCondition: 'Veuillez sélectionner au moins une condition',
    },
  },
  invoiceScanDetails: {
    breadcrumbs: {
      dashboard: 'Dashboard',
      scans: 'Numérisations',
      details: 'Détails de la numérisation',
    },
    deleteInvoice: 'Supprimer la facture',
    deleteInvoice_number: 'Supprimer la facture #{{number}}',
    deleteInvoiceWarning: 'Êtes-vous sûr de vouloir supprimer cette facture? Cette action est irréversible.',
    invoice_number: 'Facture #{{number}}',
    title: 'Détails de la numérisation',
  },
  invoiceScanList: {
    actions: 'Actions',
    breadCrumb: {
      scans: 'Numérisations',
    },
    date: 'Date de la numérisation',
    email: 'Courriel',
    invoiceNumber: 'Numéro de facture',
    listTitle: 'Numérisation des factures',
    restaurant: 'Restaurant',
    result: 'Résultat',
    scanMethod: 'Méthode de saisie',
    title: 'Liste des factures',
    status: {
      alreadyScanned: 'Déjà numérisée',
      invalidInvoice: 'Invalide',
      invoiceScan: 'En cours de validation',
      invoiceScanDeleted: 'Supprimée',
      notFound: 'Introuvable',
      notPaid: 'Non payée',
      outdatedInvoice: 'Facture trop vieille',
      pointsAdded: 'Points ajoutés',
      validInvoice: 'Valide',
      wrongPrice: 'Prix incorrect',
      wrongRestaurant: 'Mauvais restaurant',
    },
  },
  participant: {
    addPoints: 'Ajuster les points',
    breadCrumb: {
      detail: 'Participant',
      list: 'Participants',
    },
    phoneNumber: 'Numéro de téléphone',
    creation: 'Création',
    destination: 'Destination',
    email: 'Courriel',
    firstLastNameAndEmail: 'Couriel, téléphone, prénom et nom ',
    emailAndPhoneNumber: 'courriel ou téléphone',
    invalidScans: 'Numérisation invalides',
    lastScan: 'Dernière numérisation',
    listTitle: 'Participants',
    migratedTo: 'Migré vers',
    migrateUser: 'Migrer le compte',
    participantDetails: 'Détails du participant',
    reward: {
      actions: 'Actions',
      description: 'Description',
      image: 'Image',
      name: 'Nom',
      value: 'Valeur',
      dialogDelete: {
        title: 'Supprimer: {{name}}',
        description: 'Voulez-vous vraiment supprimer cette récompense ?',
        no: 'Non',
        yes: 'Oui',
        refundPoints: 'Rembourser les points de la récompense ({{points}} points)',
      },
    },
    score: 'Solde de points',
    status: 'Statut',
    selectDestination: 'Sélectionner la destination',
    source: 'Source',
    validScans: 'Numérisation valides',
    migrationWarning:
      "En cliquant sur le bouton «Migrer», l'entièreté des points ainsi que l'historique de transactions du compte source seront transférés vers le compte destination. Cette action est irréversible.",
    tabs: {
      details: 'Détails',
      transactions: 'Transactions',
      wallet: 'Portefeuille',
    },
    wallet: 'Portefeuille',
    confirmToggleActive: 'Êtes-vous sur de vouloir activer le compte de ce participant ?',
    titleConfirmActiveToggleModal: 'Activer compte du participant',
    confirmToggleDeactivate: 'Êtes-vous sur de vouloir désactiver le compte de ce participant.',
    titleConfirmDeactivateToggleModal: 'Désactiver compte du participant',
  },
  participantList: {
    actions: 'Actions',
    identification: 'Identification',
    invalidInvoices: 'Facture invalides',
    lastScanDate: 'Date du dernier scan',
    pointsTotal: 'Points total',
    status: 'Statut',
    validInvoices: 'Facture valides',
    flaggedForDeletionAt: 'Suppression en cours',
    inactive: 'Inactif',
    active: 'Actif',
  },
  searchBar: {
    search: 'Recherche par',
  },
  transactions: {
    alreadyScanned: 'Numérisation invalide | Déjà numérisée',
    details: {
      invoiceNumber: 'Numéro de facture',
      total: 'Total',
      restaurant: 'Restaurant',
      reward: 'Récompense',
      type: 'Type de transaction',
      diffPoints: 'Diff. points',
      totalPoints: 'Total points',
      date: 'Date',
    },
    expiredReward: 'Récompense expirée',
    invalid: 'Invalide',
    invalidScan: 'Numérisation invalide',
    invoiceScan: 'Validation numérisation en cours',
    migrate: 'Migrer',
    purchasedReward: 'Achat de récompense',
    removedReward: 'Suppression de récompense',
    usedReward: 'Utilisation de récompense',
    validScan: 'Numérisation valide',
    invoiceScanDeleted: 'Numérisation supprimée',
    pointsAdded: 'Points ajoutés par administration',
  },
};
